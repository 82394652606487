export const environment = {
  production: true,
  name: 'prod',

  siemensId: {
    domain: 'siemens-00255.siemens.auth0app.com',
    clientId: 'WwkD8bpXdyKbpETJyNLBh3h7IN1czVQF',
    audience: 'https://softwaremanager.api.siemens.com'
  },
  apiRoot: "https://api-software-manager.azurewebsites.net/v1/",
  auth: { redirectUri:'https://software-manager-prod.westeurope.cloudapp.azure.com'} ,

  appInsights: {
    instrumentationKey: 'f4bc7d62-bcd9-4a87-bb5a-4f123937e34c',
    applicationName: 'Software Manager'
  }
  ,
};
