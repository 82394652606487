import { ApplicationRef, DoBootstrap, Injector, NgModule } from '@angular/core';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule, Routes } from '@angular/router';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { SiLandingPageModule, SiLoadingSpinnerModule, SimplElementNgModule } from '@simpl/element-ng';

import { AppComponent } from './app.component';
import { LandingComponent } from './pages/landing/landing.component';

import { AuthClientConfig, AuthGuard, AuthHttpInterceptor, AuthModule, AuthService } from '@auth0/auth0-angular';
import { environment } from 'src/environments/environment';
import { CookieNoticeComponent } from './pages/main/cookie-notice/cookie-notice.component';
import { setAppInjector } from './util/app-injector';
import { getAuth0config } from './util/util';

const routes: Routes = [
  { path: 'landing', component: LandingComponent, pathMatch: 'full' },
  {
    path: 'cookie-notice',
    canActivate: [AuthGuard],
    canLoad: [AuthGuard],
    component: CookieNoticeComponent
  },
  {
    path: 'main',
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
    canLoad: [AuthGuard],
    loadChildren: () => import('./pages/main/main.module').then(m => m.MainModule)
  },
  { path: '**', redirectTo: 'landing' }
];

export function createTranslateLoader(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http, 'assets/i18n/', '.json');
}

@NgModule({
  declarations: [
    AppComponent,
    LandingComponent,
    CookieNoticeComponent
  ],
  imports: [
    BrowserAnimationsModule,
    SimplElementNgModule,
    AuthModule.forRoot(getAuth0config()),
    HttpClientModule,
    RouterModule.forRoot(
      routes,
      {
        useHash: false,
        enableTracing: false
      }),
    SiLandingPageModule,
    SiLoadingSpinnerModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: (createTranslateLoader),
        deps: [HttpClient]
      }
    })
  ],
  exports: [RouterModule],
  bootstrap: [AppComponent],
  providers: [
    AuthService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthHttpInterceptor,
      multi: true
    }
  ]
})
export class AppModule implements DoBootstrap {
  constructor(injector: Injector, private authConfig: AuthClientConfig) {
    setAppInjector(injector);
  }

  ngDoBootstrap(appRef: ApplicationRef): void {
    if (environment.production) {
      this.authConfig.set(getAuth0config());
    }
    appRef.bootstrap(AppComponent);
  }

}
