import { Injectable } from '@angular/core';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';

@Injectable({ providedIn: 'root' })
export class LocalisationService {
  constructor(private translate: TranslateService) {
    this.translate.setDefaultLang('en');
    this.translate.onLangChange.subscribe(this.storeLanguage);
  }

  setLanguage(): string {
    const lang = this.loadLanguage() || (this.translate.getBrowserLang() ?? 'en');
    this.setUserLanguage(lang);
    return lang;
  }

  setUserLanguage(language: string): void {
    this.translate.use(language);
  }

  private storeLanguage(event: LangChangeEvent): void {
    localStorage.setItem('lang', event.lang);
  }

  translateValueSync(value: string, params?: any) {
    return this.translate.instant(value, params);
  }

  private loadLanguage(): string {
    return localStorage.getItem('lang') || '';
  }
}
