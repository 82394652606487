import { Component } from '@angular/core';

import { LocalisationService } from './services/localisation.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  selectedValue: string;

  constructor(translate: LocalisationService) {
    this.selectedValue = translate.setLanguage();
  }
}
