import { AuthConfig } from "@auth0/auth0-angular";
import { environment } from "src/environments/environment";

export const EmailRegexExp = /^[a-zA-Z0-9.-_]+@[a-zA-Z.-]+[.]{1}[a-zA-Z]{2,}/;
export const ProductInstallationIdRegExp = /^SGP-(?:\{?(?:[0-9a-fA-F]){8}-(?:[0-9a-fA-F]){4}-(?:[0-9a-fA-F]){4}-(?:[0-9a-fA-F]){4}-(?:[0-9a-fA-F]){12}\}?)$/;
export function getAuth0config(): AuthConfig {
  return {
    useRefreshTokens: true,
    domain: environment.siemensId.domain,
    clientId: environment.siemensId.clientId,
    authorizationParams: {
      connection: 'main-tenant-oidc',
      audience: environment.siemensId.audience,
      scope: 'openid profile email',
    },
    cacheLocation: 'localstorage',
    httpInterceptor: {
      allowedList: [
        environment.apiRoot + '*'
      ]
    }
  };
}