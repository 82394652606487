<si-landing-page
  [heading]="'APP.NAME' | translate"
  [subtitle]="'APP.CLAIM' | translate"
  [links]="[
    {title: 'Corporate information', href: 'http://www.siemens.com/corporate-information'},
    {title: 'Privacy policy', href: 'http://www.siemens.com/privacy-notice'},
    {title: 'Terms of use', href: 'http://www.siemens.com/terms-of-use'},
    { title: 'Cookie notice', href:'/cookie-notice' }
  ]"
>
  <button type="submit" class="btn btn-primary w-100" (click)="loginWithRedirect()" >
    {{ 'APP.LOGIN' | translate }}
  </button>
  <div *ngIf="loading" class="spinner-wrapper">
    <si-loading-spinner></si-loading-spinner>
  </div>
</si-landing-page>
