import { Component } from '@angular/core';
import { AuthService } from '@auth0/auth0-angular';
import { environment } from 'src/environments/environment';

@Component({
  templateUrl: './landing.component.html',
  styleUrls: ['./landing.component.css']
})

export class LandingComponent {

  loading: boolean = false;

  constructor(public auth: AuthService) { }

  loginWithRedirect(): void {
    this.loading = true;
    // Call this to redirect the user to the login page
    this.auth.loginWithRedirect({
      appState: {
        target: 'main'
      },
      authorizationParams: { redirect_uri: environment.auth.redirectUri }
    });

    this.loading = false;
  }
}
