<si-navbar-primary
    [appTitle]="'APP.NAME' | translate">
</si-navbar-primary>
<div class="blockPlacement">
    <tr>
        <td class="hyperlinkStyle">
            <p><a>Cookie notice</a></p>
        </td>
    </tr>
    <tr>
        <td class="hyperlinkStyle" >
                <p><a href="https://www.siemens.com/cookie-notice">Siemens cookie notice</a></p>
                <p><a href="https://privacy.microsoft.com/privacystatement">Microsoft azure cookie
                policy</a></p>
        </td>
    </tr>
</div>